import { createContext, useContext, useState } from "react";

const UIContext = createContext();

export const UIProvider = ({ children }) => {

    const [ isMessages, setIsMessages ] = useState({
        type: '',
        message: ''
    })

    const handleAddMessage = (type, message) => setIsMessages({type: type, message: message});

    const contextValue = {
        isMessages,
        handleAddMessage
    }

    return (
        <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
    )

}

export const useUI = () => useContext(UIContext);