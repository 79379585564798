import { useAuth } from '../context/Auth/AuthContext'
import './styles/home.css'

export default function HomePage () {

    const { isAuth } = useAuth();

    const handlePlay = () => {
        if (isAuth) {
            window.location = 'https://timelinesv.arcana.codes/play'
        } else {
            window.location = 'https://timelinesv.arcana.codes/login'
        }
    }

    return (

        <div className='__wrp __wrp_home'>

            <div className='__content'>
            
                <h1 className='__title'>
                    <p className='__p_1'>Timeline</p>
                    <p className='__p_2'>San Vicente de Paúl</p>
                </h1>
                <button className='__btn_start' onClick={handlePlay}>Start</button>
            
            </div>

        </div>

    )

}