import { useState } from "react";
import { timeline } from "../../db/timeline"

import './step.css'
import { quizzes } from "../../db/quizz";
import { usePlay } from "../../context/Play/PlayContext";

export default function Steps ({ step }) {

    const [ isQuizz, setIsQuizz ] = useState(false);
    const { responsesQuizz, handleResponseQuizz, handleContinueTime, handleFinishedPlay } = usePlay();

    const info = timeline.find((time) => time.id === step);
    const quizzed = quizzes.find((quizz) => quizz.id === step);

    const handleSelectedResponse = (q, r) => {
        handleResponseQuizz(q, r);
    }

    const responseState = responsesQuizz[`quizz${step}`];

    const handleContinueTimeLine = () => {
        setIsQuizz(false);
        handleContinueTime(step + 1)
    }

    return (

        <>
        
            {!isQuizz ? (
            
                <div style={{position: 'relative', height: 'inherit'}}>

                    <p>{info?.time}</p>
                    <h2>{info?.title}</h2>

                    <div className="__content_time">

                        <div className="__text">
                            <p>{info?.text}</p>
                        </div>
                        <div className="__images">
                            {info?.illustations && info.illustations.length > 0 && (
                                info?.illustations.map((img, index) => (
                                    <img key={index} src={img} alt={`Imágenes sobre el ${info?.title} de San Vicente de Paúl`} />
                                ))
                            )}
                        </div>

                    </div>

                    <button className="__btn_next" onClick={() => setIsQuizz(true)}>Continue</button>

                </div>

            ) : (

                <div>

                    <h2>{quizzed?.question}</h2>

                    <div className="__responses">
                        {quizzed?.options.map((opt, index) => (
                            <div key={index} className={`__response ${responseState === opt ? '__response_active' : ''}`} onClick={() => handleSelectedResponse( `quizz${quizzed.id}`, opt)}><p>{opt}</p></div>
                        ))}
                    </div>

                    {responseState !== '' && (
                        step === 10 ? (
                            <button className="__btn_next" onClick={handleFinishedPlay}>Finish</button>
                        ) : (
                            <button className="__btn_next" onClick={handleContinueTimeLine}>Next</button>
                        )
                    )}

                </div>

            )}

        </>

    )

}