import { useAuth } from '../context/Auth/AuthContext'
import './styles/login.css'

export default function LoginPage () {

    const { isAuth, handleChangeInfo, handleAuth } = useAuth();

    const handleGo = () => window.location = 'https://timelinesv.arcana.codes/play';

    return (

        <div className='__screen'>

            {!isAuth ? (
            <div>

                <h1 className='__title'>Let's get started</h1>
                <p className='__p'>Save your account to start the journey</p>

                <div className='__form'>

                    <div className='__form_group'>

                        <label htmlFor="name" className="__label">Enter your full name</label>
                        <input type="text" className="__entry" name="username" id="name" placeholder="Enter your full name" aria-placeholder="Enter your full name" onChange={(e) => handleChangeInfo(e)} />

                    </div>

                    <div className='__form_group __form_flex'>
                        <div className='__form_control'>

                            <label className="__label" htmlFor="grade">Enter your grade</label>
                            <select className='__entry' name='grade' onChange={(e) => handleChangeInfo(e)}>
                                <option value={'1'}>1st</option>
                                <option value={'2'}>2nd</option>
                                <option value={'3'}>3rd</option>
                                <option value={'4'}>4th</option>
                                <option value={'5'}>5th</option>
                            </select>

                        </div>

                        <div className='__form_control'>

                            <label className="__label" htmlFor="section">Enter your section</label>
                            <select className='__entry' name='section' onChange={(e) => handleChangeInfo(e)}>
                                <option value={'A'}>A</option>
                                <option value={'B'}>B</option>
                                <option value={'C'}>C</option>
                                <option value={'D'}>D</option>
                                <option value={'E'}>E</option>
                            </select>

                        </div>
                    </div>

                    <div className='__form_group'>
                        <button className='__btn_save' onClick={handleAuth}>Save progress</button>
                    </div>

                </div>

            </div>
            ) : (

                <div>
                    <h1 className='__title'>Perfect</h1>
                    <p className='__p'>Click continue to begin</p>

                    <div className='__form'>
                        <div className='__form_group'>
                            <button className='__btn_save' onClick={handleGo}>Continue</button>
                        </div>
                    </div>

                </div>

            )}

        </div>

    )

}