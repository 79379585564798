import { createContext, useCallback, useContext, useEffect, useState } from "react";
import Cookie from "js-cookie";
import { useUI } from "../UI/UIContext";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "../../api/API";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const { handleAddMessage } = useUI();

    const [ isAuth, setIsAuth ] = useState(false);
    const [ isUser, setIsUser ] = useState();

    const [ user, setUser ] = useState({
        username: '',
        grade: '',
        section: '',
    });

    const handleChangeInfo = (e) => {
        const { name, value } = e.target;
        setUser(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleAuth = async () => {

        try {
            
            const date = moment().format('YYYY-MM-DD HH:mm:ss')

            const formData = new FormData();
            formData.append('name', user.username);
            formData.append('grade', user.grade);
            formData.append('section', user.section)
            formData.append('date', date)

            const response = await fetch(`${API_URL}/api/auth/auth.php`, {
                method: 'POST',
                body: formData
            })

            const data = await response.json();

            if (data.ok) {

                Cookie.set('arcana_user_svplay', data.token)
                const decoded = jwtDecode(data.token)
                setIsUser(decoded)
                setIsAuth(true);
                handleAddMessage('success', data.message)

            } else {
                
                handleAddMessage('warning', data.message)
                
            }

        } catch (error) {
         
            handleAddMessage('error', error.message)
                
        }

    }


    const checkedSession = useCallback(async () => {
        
        try {
            const cookieSession = Cookie.get("arcana_user_svplay");
            if (cookieSession) {
                const decodedUser = jwtDecode(cookieSession);
                setIsUser(decodedUser)
                setIsAuth(true);
                handleAddMessage("success", "Nuevo inicio de sesión");
            } else {
                setIsAuth(false);
                handleAddMessage("warning", "Ingresa primero a tu cuenta");
            }
        } catch (error) {
            handleAddMessage("error", "Hubo un error al inicio de sesión");
        }
    }, [handleAddMessage]); 

    useEffect(() => {
        if (!isAuth || isUser === '') {
            checkedSession();
        }
    }, [])

    const contextValue = {
        isAuth,
        isUser,
        handleChangeInfo,
        handleAuth
    }

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    )

}

export const useAuth = () => useContext(AuthContext);