export const quizzes = [
    {
        "id": 1,
        "question": "Where was Saint Vincent de Paul born?",
        "options": [
            "Ranquines, France",
            "Paris, France",
            "Dax, France",
            "Lyon, France"
        ],
        "correctAnswer": "Ranquines, France",
        "points": 100
    },
    {
        "id": 2,
        "question": "At what age was Saint Vincent de Paul ordained as a priest?",
        "options": [
            "19 years old",
            "25 years old",
            "30 years old",
            "15 years old"
        ],
        "correctAnswer": "19 years old",
        "points": 100
    },
    {
        "id": 3,
        "question": "What happened to Saint Vincent between 1605 and 1607?",
        "options": [
            "He was captured by pirates and taken to Algiers",
            "He founded the Congregation of the Mission",
            "He met Saint Louise de Marillac",
            "He was canonized"
        ],
        "correctAnswer": "He was captured by pirates and taken to Algiers",
        "points": 100
    },
    {
        "id": 4,
        "question": "With whom did Saint Vincent collaborate in his charity work after his return to France?",
        "options": [
            "Catherine Labouré",
            "Saint Louise de Marillac",
            "His father",
            "The Bishop of Dax"
        ],
        "correctAnswer": "Saint Louise de Marillac",
        "points": 100
    },
    {
        "id": 5,
        "question": "Which congregation did Saint Vincent found in 1625?",
        "options": [
            "Daughters of Charity",
            "Congregation of the Mission",
            "Order of Saint Francis",
            "Discalced Carmelites"
        ],
        "correctAnswer": "Congregation of the Mission",
        "points": 100
    },
    {
        "id": 6,
        "question": "In what year did Saint Vincent found the Daughters of Charity?",
        "options": [
            "1633",
            "1640",
            "1625",
            "1620"
        ],
        "correctAnswer": "1633",
        "points": 100
    },
    {
        "id": 7,
        "question": "What types of programs did Saint Vincent organize in the 1630s?",
        "options": [
            "Charity works",
            "Foreign missions",
            "Music schools",
            "Business foundations"
        ],
        "correctAnswer": "Charity works",
        "points": 100
    },
    {
        "id": 8,
        "question": "What was established in several cities in France during the 1640s?",
        "options": [
            "Charity houses",
            "Universities",
            "Hospitals",
            "Libraries"
        ],
        "correctAnswer": "Charity houses",
        "points": 100
    },
    {
        "id": 9,
        "question": "What date marked the death of Saint Vincent de Paul?",
        "options": [
            "September 27, 1660",
            "June 16, 1737",
            "April 24, 1581",
            "September 23, 1600"
        ],
        "correctAnswer": "September 27, 1660",
        "points": 100
    },
    {
        "id": 10,
        "question": "When was Saint Vincent de Paul canonized?",
        "options": [
            "June 16, 1737",
            "September 27, 1660",
            "January 1, 1700",
            "April 24, 1581"
        ],
        "correctAnswer": "June 16, 1737",
        "points": 100
    }
]