import { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";
import { quizzes } from "../../db/quizz";
import { useAuth } from "../Auth/AuthContext";
import { API_URL } from "../../api/API";
import { useUI } from "../UI/UIContext";

const PlayContext = createContext();

export const PlayProvider = ({ children }) => {

    const { handleAddMessage } = useUI();
    const { isUser } = useAuth();

    const cookieStep = Cookies.get('arcana_step_svplay')
    const [ stepPlay, setStepPlay ] = useState(cookieStep || 1);

    const [ isStarted, setIsStarted ] = useState(false);
    const [ isScore, setIsScore ] = useState(0);
    const [ isDuration, setIsDuration ] = useState(0);

    const [ bestPlayers, setBestPlayers ] = useState([]);

    const [ responsesQuizz, setResponsesQuizz ] = useState({
        quizz1: '',
        quizz2: '',
        quizz3: '',
        quizz4: '',
        quizz5: '',
        quizz6: '',
        quizz7: '',
        quizz8: '',
        quizz9: '',
        quizz10: '',
    });
    
    const handleResponseQuizz = (qzz, response) => {
        setResponsesQuizz(prev => ({
            ...prev,
            [qzz]: response
        }))
    }

    const handleContinueTime = (stp) => {
        setStepPlay(stp);
    }

    const handleFinishedPlay = async () => {
        
        let scoreFinal = 0;

        quizzes.forEach((quiz) => {
            const userResponse = responsesQuizz[`quizz${quiz.id}`];
            if (userResponse === quiz.correctAnswer) {
                scoreFinal += quiz.points; // Sumar puntos de la respuesta correcta
            }
        });

        try {
            
            const formData = new FormData();
            formData.append('player', isUser.id_player);
            formData.append('score', scoreFinal);
            formData.append('duration', isDuration);

            const response = await fetch(`${API_URL}/api/play/`, {
                method: 'POST',
                body: formData
            })

            if (!response.ok) {
                throw new Error('Error en la respuesta del servidor');
            }

            const data = await response.json();

            if (data.ok) {
                setIsScore(scoreFinal);
                handleAddMessage('success', data.message)
                setIsStarted(false)
            } else {
                handleAddMessage('warning', data.message)
                setIsStarted(false)
            }

        } catch (error) {
            handleAddMessage('error', error.message)
            setIsStarted(false)
        }


    }

    const handleBestPlayers = async () => {

        try {
            
            const response = await fetch(`${API_URL}/api/play/best/`);

            if (!response.ok) {
                throw new Error('Error en la respuesta del servidor');
            }

            const data = await response.json();
            
            if (data.ok) {
                setBestPlayers(data.players);
            } else {
                handleAddMessage('warning', data.message)
            }

        } catch (error) {
            handleAddMessage('error', error.message)
        }

    }

    const contextValue = {
        stepPlay,
        isStarted,
        isScore,
        isDuration,
        bestPlayers,
        setIsStarted,
        setIsDuration,
        responsesQuizz,
        handleContinueTime,
        handleResponseQuizz,
        handleFinishedPlay,
        handleBestPlayers
    }

    return (

        <PlayContext.Provider value={contextValue}>{children}</PlayContext.Provider>
        
    )

}

export const usePlay = () => useContext(PlayContext);