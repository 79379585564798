import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LayoutPage from "./page/LayoutPage";
import { AuthProvider } from "./context/Auth/AuthContext";
import { UIProvider } from "./context/UI/UIContext";

import './assets/css/global.css'
import HomePage from "./page/HomePage";
import LoginPage from "./page/LoginPage";
import PlayPage from "./page/PlayPage";
import { PlayProvider } from "./context/Play/PlayContext";

const router = createBrowserRouter([
    {
        path: '/',
        element: <LayoutPage/>,
        children: [
            {
                path: '/',
                element: <HomePage/>
            },
            {
                path: '/login',
                element: <LoginPage/>
            },
            {
                path: '/play',
                element: <PlayPage/>
            }
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <UIProvider>

        <AuthProvider>

            <PlayProvider>
        
                <RouterProvider router={router} />
            
            </PlayProvider>
        
        </AuthProvider>
    
    </UIProvider>

);