import { useAuth } from '../context/Auth/AuthContext'
import './styles/play.css'

import avatar from '../assets/img/1.png'
import { usePlay } from '../context/Play/PlayContext';
import Steps from '../components/play/Steps';
import { useEffect } from 'react';
import Best from '../components/play/Best';

export default function PlayPage () {

    const { isUser } = useAuth();
    const { isStarted, setIsStarted, stepPlay, isScore, isDuration, setIsDuration } = usePlay();

    useEffect(() => {
        let timer;

        if (isStarted) {
            const startTime = Date.now(); // Guarda el tiempo de inicio
            timer = setInterval(() => {
                const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
                setIsDuration(elapsedSeconds); // Actualiza la duración cada segundo
            }, 1000);
        }

        return () => clearInterval(timer); // Limpia el intervalo al desmontar o cuando isStarted cambie
    }, [isStarted, setIsDuration]);

    return (

        <div className="__wrp">

            <div className='__box'>

                <div style={{width: '70%'}}>

                    <div className='__header'>

                        <div className='__col1'>
                            <figure className='__photo'>
                                <img src={avatar} alt={`Avatar de ${isUser?.name || isUser?.name_player}`}/>
                            </figure>
                            <div className='__name'>
                                <h3>Welcome, {isUser?.name || isUser?.name_player}</h3>
                                <p>Grade and section: "{isUser?.grade || isUser?.grade_player} {isUser?.section || isUser?.section_player}"</p>
                            </div>
                        </div>

                        <div className='__col2'>
                            <p>time: {isDuration}</p>
                            <p>score: {isScore}</p>
                        </div>

                    </div>

                    <div className='__timeline'>
                        <div className='__line'></div>
                        <div className={`__time ${stepPlay === 1 ? '__time_active' : ''}`}>
                            <div className='__square_time'>1</div>
                        </div>
                        <div className={`__time ${stepPlay === 2 ? '__time_active' : ''}`}>
                            <div className='__square_time'>2</div>
                        </div>
                        <div className={`__time ${stepPlay === 3 ? '__time_active' : ''}`}>
                            <div className='__square_time'>3</div>
                        </div>
                        <div className={`__time ${stepPlay === 4 ? '__time_active' : ''}`}>
                            <div className='__square_time'>4</div>
                        </div>
                        <div className={`__time ${stepPlay === 5 ? '__time_active' : ''}`}>
                            <div className='__square_time'>5</div>
                        </div>
                        <div className={`__time ${stepPlay === 6 ? '__time_active' : ''}`}>
                            <div className='__square_time'>6</div>
                        </div>
                        <div className={`__time ${stepPlay === 7 ? '__time_active' : ''}`}>
                            <div className='__square_time'>7</div>
                        </div>
                        <div className={`__time ${stepPlay === 8 ? '__time_active' : ''}`}>
                            <div className='__square_time'>8</div>
                        </div>
                        <div className={`__time ${stepPlay === 9 ? '__time_active' : ''}`}>
                            <div className='__square_time'>9</div>
                        </div>
                        <div className={`__time ${stepPlay === 10 ? '__time_active' : ''}`}>
                            <div className='__square_time'>10</div>
                        </div>
                    </div>

                    <div className='__wrp_play'>
                    
                        <div className='__play'>

                            <div className='__content_play'>
                                {isStarted ? (
                                    <Steps step={stepPlay} />
                                ) : (
                                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                                    
                                        <h2 style={{textAlign: 'center'}}>Let's Go</h2>
                                        <p style={{textAlign: 'center', margin: '2rem 0rem'}}>Finish correctly in the shortest time to save a better score.</p>

                                        <button onClick={() => setIsStarted(true)} style={{textAlign: 'center', padding: '.8rem 1rem', backgroundColor: '#0062ff', color: '#FFF', borderRadius: '100px'}}>Start</button>

                                    </div>
                                )}
                            </div>
                        
                        </div>
                    
                    </div>
                
                </div>

                <Best/>

            </div>

        </div>

    )

}