import { Outlet } from 'react-router-dom'

import './styles/layout.css'
import { useUI } from '../context/UI/UIContext'
import { useEffect } from 'react';

export default function LayoutPage () {

    const { isMessages, handleAddMessage } = useUI();

    useEffect(() => {
        setTimeout(() => {
            handleAddMessage('', '')
        }, 3000);
    }, [handleAddMessage])

    return (

        <div className='__app'>

            {isMessages.message !== '' && <div className={`__alert __alert_${isMessages.type}`}>{isMessages.message}</div>}

            <Outlet/>

        </div>

    )

}