import { useEffect } from "react";
import { API_URL } from "../../api/API";
import { usePlay } from "../../context/Play/PlayContext"

export default function Best() {

    const { bestPlayers, handleBestPlayers } = usePlay();

    useEffect(() => {
        if (bestPlayers.length === 0) {
            handleBestPlayers();
        }
    }, [bestPlayers.length, handleBestPlayers]);

    return (

        <div className='__scores'>
            <div className='__scores_player'>
                <h4>Mejores jugadores</h4>
                <div className='__line'></div>
                {bestPlayers.map((player) => (
                    <div className='__play' key={player.id_player}>
                        <div className='__col1'>
                            <div className='__avatar'>
                                <img src={`${API_URL}/api/avatars/${player.avatar_player}.png`} alt={`Avatar de ${player.name_player}`} />
                            </div>
                            <div className='__name'>
                                <h5>{player.name_player}</h5>
                                <p>{player.grade_player} {player.section_player}</p>
                            </div>
                        </div>
                        <div className='__col2'>
                            <p>{player.score_player} | {player.time_player}s</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )

}