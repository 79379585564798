export const timeline = [
    {
        id: 1,
        title: "Birth",
        text: "Saint Vincent de Paul was born on April 24, 1581, in Ranquines, France, to a peasant family. His parents, Jean de Paul and Bertrande de Moras, instilled in him values of faith and hard work. From a young age, he showed a strong interest in spiritual life and service to others, foreshadowing his future vocation.",
        time: "April 24, 1581",
        illustations: [
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8DTKkdVO_Bs3DoUbwBmD7raaRbPpRmLBrLg&s", 
        ]
    },
    {
        id: 2,
        title: "Ordination as a priest",
        text: "At the age of 19, in 1600, he was ordained a priest in the same place where he grew up. During this period, Saint Vincent began to develop his understanding of charity and service to others, influenced by his mentor, the Bishop of Dax, who taught him the importance of helping the poor and destitute.",
        time: "September 23, 1600",
        illustations: [
            "https://i0.wp.com/vincentians.com/es/wp-content/uploads/sites/11/2016/10/San-Vicente-.jpg?fit=424%2C638&ssl=1"
        ]
    },
    {
        id: 3,
        title: "Captivity",
        text: "In 1605, Saint Vincent was captured by pirates and taken to Algiers, where he spent two years as a slave. During this time, he lived in harsh conditions and had several masters, which allowed him to experience human suffering firsthand. This profound and painful experience was crucial to his spiritual development and commitment to charity.",
        time: "1605-1607",
        illustations: [
            "https://asociacionfatima.org.ar/wp-content/uploads/2020/09/SOD-0927-SaintVincentdePaul-790x480-1.jpg"
        ]
    },
    {
        id: 4,
        title: "Return to France",
        text: "After being released in 1607, Saint Vincent returned to France. His experience in Algiers transformed him, and he decided to dedicate himself to serving the poor, organizing aid for those most in need in his community. This commitment was strengthened by his encounter with Saint Louise de Marillac, who became his collaborator in social work.",
        time: "1607",
        illustations: [
            "https://i0.wp.com/vincentians.com/es/wp-content/uploads/sites/11/2013/04/e-1.jpg?fit=846%2C494"
        ]
    },
    {
        id: 5,
        title: "Founding of the Congregation of the Mission",
        text: "In 1625, Saint Vincent founded the Congregation of the Mission, aimed at evangelizing and training priests. The congregation focused on education and clergy formation, working on teaching faith and social commitment. His vision was inspired by his desire to see a more active Church in society.",
        time: "April 17, 1625",
        illustations: [
            "https://www.fatima.pe/images/new/2013/vs141b.jpg"
        ]
    },
    {
        id: 6,
        title: "First charitable works",
        text: "During the 1630s, Saint Vincent organized various charitable works in Paris, establishing programs to help the poor, orphans, and the sick. His dedication was so profound that he collaborated with Catherine Labouré, who later became the famous visionary of the Miraculous Medal, thus strengthening faith and charitable action in his community.",
        time: "1630",
        illustations: [
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLocXgcj51PAzrh3QRmxeBlkET1dH7hitwK8rrvREM49Oi7lBnoVfticFNKe-4TtkkXRw&usqp=CAU"
        ]
    },
    {
        id: 7,
        title: "Creation of the Daughters of Charity",
        text: "In 1633, Saint Vincent established the Company of the Daughters of Charity, an innovative order dedicated to serving the poor and sick. Alongside Saint Louise de Marillac, Saint Vincent trained these women, empowering them to carry out their charitable mission, marking a significant change in the role of women in religious and social life.",
        time: "November 29, 1633",
        illustations: [
            "https://i0.wp.com/vincentians.com/es/wp-content/uploads/sites/11/2016/08/con-damas-de-la-caridad.jpg?fit=846%2C444&ssl=1"
        ]
    },
    {
        id: 8,
        title: "Opening of charity houses",
        text: "In the 1640s, Saint Vincent established charity houses in several cities in France, expanding his social work. These houses not only provided shelter and medical care but also served as educational centers where people were taught to read and write, demonstrating his comprehensive commitment to the well-being of the needy.",
        time: "1640s",
        illustations: [
            "https://elcatolicismo.com.co/sites/default/files/noticias/New%20Project%20%2816%29_9.jpg"
        ]
    },
    {
        id: 9,
        title: "Death",
        text: "Saint Vincent de Paul passed away on September 27, 1660, in Paris, leaving a legacy of love and service to the poor. His death was deeply felt by those he served and by the Daughters of Charity, who continued his work. His funeral was a significant event that attracted many people from the community.",
        time: "September 27, 1660",
        illustations: [
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWZu0fc4t-BjJGOZwh4VwUGwvI66ucM0kkgQ&s"
        ]
    },
    {
        id: 10,
        title: "Canonization",
        text: "He was canonized on June 16, 1737, by Pope Clement XII. The canonization of Saint Vincent de Paul officially recognized him as the saint of charity, consolidating his place in history as a fervent defender of the poor and a model of Christian virtue. His legacy endures in the work of millions of people who continue his mission of service.",
        time: "June 16, 1737",
        illustations: [
            "https://www.corazondepaul.org/wp-content/uploads/2020/05/san-vicente-de-paul-patrono-de-las-obras-de-caridad-leon-xiii.jpg"
        ]
    }
];